import React from 'react';
import { graphql, Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import {
  Box, Text, SimpleGrid, useColorModeValue, Flex, useTheme, useBreakpointValue,
} from '@chakra-ui/react';

import Layout from '../components/layout';
import PageHeading from '../components/page-heading';

type CategoryProps = {
  pageContext: {
    category: {
      frontmatter: {
        label: string;
      }
    }
  }
  data: {
    allMarkdownRemark: {
      nodes: Array<{
        fields: {
          slug: string;
        },
        frontmatter: {
          title: string;
          image: {
            childImageSharp: {
              fluid: FluidObject
            }
          }
        }
      }>
    }
  }
}

const Category = ({ data, pageContext }: CategoryProps) => {
  const { nodes: artworks } = data.allMarkdownRemark;
  const maxHeight = useBreakpointValue({ base: '300px', md: '200px' });
  const radius = useBreakpointValue({ base: 'none', md: 'md' });

  return (
    <Layout>
      <Box py={12}>
        <Box px={8}>
          <PageHeading text={pageContext.category.frontmatter.label} />
        </Box>
        <SimpleGrid columns={[1, 2, 3]} spacing={12} pt={8} px={{ base: 0, md: 8 }}>

          {artworks.map(({ fields, frontmatter }) => (
            <Flex
              key={frontmatter.title}
              direction="column"
              justifyContent="flex-end"
            >
              <Box
                as={Link}
                to={fields.slug}
                rounded={radius}
                _hover={{
                  boxShadow: 'xl',
                  transition: '0.1s all',
                  transform: 'scale(1.01)',
                }}
              >
                <Img
                  fluid={frontmatter.image.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: 'cover',
                    maxHeight,
                  }}
                  style={{
                    borderTopLeftRadius: radius,
                    borderTopRightRadius: radius,
                    maxHeight,
                  }}
                />
                <Text
                  boxShadow="lg"
                  fontSize="sm"
                  background={useColorModeValue('white', 'gray.700')}
                  color={useColorModeValue('grey.800', 'gray.200')}
                  p={4}
                  fontWeight={600}
                  borderBottomLeftRadius={radius}
                  borderBottomRightRadius={radius}
                >
                  {frontmatter.title}

                </Text>
              </Box>
            </Flex>
          ))}
        </SimpleGrid>
      </Box>
    </Layout>
  );
};

export const categoryQuery = graphql`
  query ArtworksByCategory($categorySlug : String!) {
  allMarkdownRemark(filter: {frontmatter: {category: {eq: $categorySlug}}}) {
    nodes {
      fields {
        slug
      }
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
          }
        }
      }
    }
  }
}`;

export default Category;
